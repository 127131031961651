import {
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import axios from "axios";
import { UPLOAD_API } from "../../../data/Links";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import { getAuthentication } from "../../../auth";

const API_URL = UPLOAD_API;

const AddNewAds = ({ handleSubmit, closingModal }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [uploadedFileName, setUploadedFileName] = useState("");
  const [alt, setAlt] = useState("");
  const [link, setLink] = useState("");
  const [imageFile, setImageFile] = useState(null);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageFile(e.target.files[0]);
    }
  };

  const uploadFile = async () => {
    if (!imageFile) return;
    try {
      const formData = new FormData();
      formData.append("image", imageFile);

      const res = await axios.post(API_URL, formData, {
        headers: {
          token: `Bearer ${getAuthentication()}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.status === 201) {
        setUploadedFileName(res.data.name);
      }
    } catch (error) {
      console.error("Upload error:", error);
    }
  };

  const HandleSubmitBtn = () => {
    handleSubmit(uploadedFileName, alt, link);
    closingModal();
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        height: "80%",
        bgcolor: colors.primary[400],
        overflowY: "scroll",
        boxShadow: 24,
        p: 4,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton onClick={closingModal} color="error">
          <CloseIcon />
        </IconButton>
      </Box>

      <Typography variant="h3" color="secondary" sx={{ textAlign: "center" }}>
        Ad
      </Typography>

      <Divider />
      <Box>
        <TextField
          sx={{ mt: 3 }}
          fullWidth
          variant="filled"
          type="text"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          label="Link ..."
          color="secondary"
        />
      </Box>
      <Box>
        <TextField
          sx={{ mt: 3 }}
          fullWidth
          variant="filled"
          type="text"
          value={alt}
          onChange={(e) => setAlt(e.target.value)}
          label="Image Description ..."
          color="secondary"
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 1 }}>
        <Button
          sx={{ mx: 1 }}
          color="primary"
          variant="contained"
          component="label"
        >
          Browse Image
          <input type="file" accept="image/*" onChange={onSelectFile} hidden />
        </Button>
        <Button
          sx={{ mx: 1 }}
          color="warning"
          variant="contained"
          onClick={uploadFile}
          disabled={!imageFile}
        >
          Upload Image
        </Button>
      </Box>

      {uploadedFileName && (
        <Typography sx={{ textAlign: "center" }} color="secondary">
          Image Uploaded Successfully
        </Typography>
      )}

      {uploadedFileName && (
        <Button
          onClick={HandleSubmitBtn}
          sx={{ mt: 2 }}
          variant="contained"
          color="secondary"
        >
          Add New Ad
        </Button>
      )}
    </Box>
  );
};

export default AddNewAds;
